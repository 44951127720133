/* eslint-disable @typescript-eslint/camelcase */

import ITTIModel from '../../ittijs/ITTIModel';
export default class extends ITTIModel {
	constructor() {
		super('Reports');
	}
	report1(session_id, station_id){
		return this.fetch('report1', { session_id, station_id });
	}
}