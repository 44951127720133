<template>
  <div v-html="content" class="pa-2"></div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import Model from './Model'

const model = new Model

export default {
  props: [
    'report',
    'session',
    'station',
  ],
  data(){
    return {
      content: '',
      reload: false,
    }
  },
  watch: {
    session(){
      this.reload = true;
    },
    station(){
      this.reload = true;
    },
    reload(val){
      if (val) {
        this.mounted();
        this.reload = false;
      }
    },
  },
  mounted(){

    model
    .fetch(this.report, {session_id: this.session, station_id: this.station})
      .then(rsp=>{
        this.content = rsp;
      })
    ;
  },
}
</script>